import './App.css';
import 'src/components/styles/Styles.css';

import { FC, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider, RouterProviderProps } from 'react-router';
import { ToastContainer } from 'src/components/singleton/ToastContainer';
import { initializeLog } from 'src/lib/log';
import { initializeGTM } from 'src/lib/gtm';
import { CommonHead } from 'src/components/CommonHead';

export const App: FC<{ router: RouterProviderProps['router'] }> = ({ router }) => {
  useEffect(() => {
    // Activate Google Analytics with and Echo VITE_GOOGLE_ANALYTICS = {Measurement ID}
    if (!import.meta.env.SSR) {
      initializeLog(import.meta.env.VITE_GOOGLE_ANALYTICS);
    }
    // Activate Google Tag Manager with VITE_GOOGLE_TAG_MANAGER = {Measurement ID}
    if (import.meta.env.VITE_GOOGLE_TAG_MANAGER && !import.meta.env.SSR) {
      initializeGTM(import.meta.env.VITE_GOOGLE_TAG_MANAGER);
    }
  }, []);

  // Render application with router.
  return (
    <HelmetProvider>
      <CommonHead />
      <ToastContainer />
      <RouterProvider router={router} />
    </HelmetProvider>
  );
};
