import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Account } from 'src/models/v1/account';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const echoContext: Record<string, string | null> = {};

export const initializeLog = (MESUREMENT_ID?: string | undefined) => {
  if (import.meta.env.SSR) return;
  if (MESUREMENT_ID) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', 'G-S8X9DK2RJX', {
      send_page_view: false,
    });

    // Global Site Tag (gtag.js) - Google Analytics
    const script = document.createElement('script');
    script.id = 'google-analytics';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${MESUREMENT_ID}`;
    document.body.appendChild(script);
  }
  // Capture gclid from url.
  const url = new URL(window.location.href);
  const gclid = url.searchParams.get('gclid') || sessionStorage.getItem('gclid');
  if (gclid) {
    sessionStorage.setItem('gclid', gclid);
    echoContext.gclid = gclid;
  }
};

let lastPath = '';

export const logContext = (context: Record<string, string | null>) => {
  Object.assign(echoContext, context);
};

export const pageview = ({ me }: { me: Account | null }) => {
  const location = useLocation();
  useEffect(() => {
    if (import.meta.env.SSR) return;
    setTimeout(() => {
      const attributes = {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      };
      if (attributes.page_path == lastPath) return;
      console.log('[PageView]', attributes.page_title, '-', attributes.page_path);
      // Google Analytics
      if (typeof window.gtag == 'function') window.gtag('event', 'page_view', attributes);
      // NAGAKU Echo
      log('page_view', { ...attributes, account_gid: me?.gid });
      lastPath = attributes.page_path;
    });
  }, [location.key]);
};

const logRepository: {
  logBuffer: Record<string, any>[];
  logBufferTimeout: NodeJS.Timeout | null;
} = {
  logBuffer: [],
  logBufferTimeout: null,
};

const sessionId = import.meta.env.SSR ? '' : window.sessionStorage.getItem('sessionId') || crypto.randomUUID();
if (!import.meta.env.SSR) {
  window.sessionStorage.setItem('sessionId', sessionId);
}

export const log = (event: string, attributes: Record<string, any>) => {
  if (import.meta.env.SSR) return;
  if (!import.meta.env.VITE_NAGAKU_ECHO_URL) return;
  logRepository.logBuffer.push({
    ...attributes,
    ...echoContext,
    event,
    timestamp: new Date().toISOString(),
  });
  if (logRepository.logBufferTimeout) clearTimeout(logRepository.logBufferTimeout);
  if (logRepository.logBuffer.length > 32) {
    // Flush buffer when it exceeds 32 events (~64kb) to stay under keepalive limit of 64kb
    flushLogBuffer();
  } else {
    logRepository.logBufferTimeout = setTimeout(() => flushLogBuffer(), 10000);
  }
};

const flushLogBuffer = () => {
  if (logRepository.logBuffer.length > 0 && import.meta.env.VITE_NAGAKU_ECHO_URL) {
    fetch(import.meta.env.VITE_NAGAKU_ECHO_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Echo-Debug': import.meta.env.DEV ? 'true' : 'false',
        'Echo-Session-Id': sessionId,
      },
      body: JSON.stringify(logRepository.logBuffer),
      keepalive: true,
    });
    logRepository.logBuffer = [];
  }
};

if (!import.meta.env.SSR && typeof window.addEventListener === 'function') {
  window.addEventListener('beforeunload', flushLogBuffer);
}
