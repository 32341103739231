import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, ScrollRestoration, useLoaderData, useRevalidator } from 'react-router';
import { api } from 'src/lib/api';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { logContext, pageview } from 'src/lib/log';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { Permission } from 'src/models/v1/permission';

import * as Sentry from '@sentry/react';
import { NavigationProgressBar } from 'src/components/primitives/NavigationProgress';

type Data = {
  account: Account | null;
  permissions: Array<Permission>;
};

export const Root: FC = () => {
  // Subscribe api status.
  const { revalidate } = useRevalidator();
  useEffect(() => {
    api.addEventListener('update', revalidate);
    return () => api.removeEventListener('update', revalidate);
  }, [revalidate]);

  // Manage current status.
  const { account: me, permissions } = useLoaderData() as Data;

  // Log page view.
  logContext({
    account_gid: me?.gid || null,
  });
  pageview({ me });

  const { i18n } = useI18n();

  useEffect(() => {
    Sentry.setUser(me && { id: me.gid, username: me.display_name });
  }, [me]);

  return (
    <CurrentStatusContext value={{ me, permissions }}>
      <Helmet>
        <meta property="og:site_name" content={i18n.t('nagaku')} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${import.meta.env.VITE_REPAIR_WEB_URL || 'https://nagaku.com'}/ogp.png`} />
        <meta property="og:locale" content={i18n.locale() === 'ja' ? 'ja_JP' : 'en_US'} />
        <meta name="theme-color" content="#02192f" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@nagaku_jp" />
      </Helmet>
      <NavigationProgressBar />
      <ScrollRestoration />
      <Outlet />
    </CurrentStatusContext>
  );
};
