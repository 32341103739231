import { FC, useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, redirect, useLoaderData, useLocation, useNavigate } from 'react-router';

import { NavigationLayout } from 'src/components/Layout';
import { AccountDebug } from 'src/components/features/account/AccountDebug';
import { AccountNavigation, AccountNavigationValue } from 'src/components/features/account/AccountNavigation';
import { AccountProfile } from 'src/components/features/account/AccountProfile';
import { OpenTicketStickyBar } from 'src/components/features/ticket/OpenTicketStickyBar';

import { AccountContext, generateTitle } from 'src/lib/account';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { NotFoundError } from 'src/lib/errors';
import { isValidULID, isValidScreenName } from 'src/lib/validation';

import { Account } from 'src/models/v1/account';
import { OpenTicketBar } from 'src/components/features/ticket/OpenTicketBar';
import { useInView } from 'src/lib/inView';

export type Data = {
  account: Account;
};

const NAVIGATION_TABLE: { [value in AccountNavigationValue]: string } = {
  works: '',
  about: '/about',
};

export const AccountProfilePage: FC = () => {
  const location = useLocation();
  const { i18n } = useI18n();
  const { account } = useLoaderData() as Data;
  const { pathname } = useLocation();
  const openTicketBarRef = useRef(null);
  const openTicketBarRefInView = useInView(openTicketBarRef);

  const navigate = useNavigate();
  const onNavigate = useCallback(
    (value: AccountNavigationValue) =>
      navigate(`/${account.screen_name || `accounts/${account.gid}`}${NAVIGATION_TABLE[value]}`, {
        replace: true,
        preventScrollReset: true,
      }),
    [navigate, account.screen_name, account.gid],
  );
  const defaultValue = useMemo(() => {
    if (pathname.endsWith('/about')) return 'about';
    return 'works';
  }, [pathname]);

  return (
    <NavigationLayout>
      <Helmet>
        <title>{generateTitle(account, i18n.t('nagaku'))}</title>
        <meta property="og:title" content={generateTitle(account, i18n.t('nagaku'))} />
        <meta name="description" content={`${i18n.t('description.prefix')}${account.bio || ''}`} />
        <meta property="og:description" content={`${i18n.t('description.prefix')}${account.bio || ''}`} />
        <meta property="og:image" content={account.icon_url?.concat('/s2048')} />
        <meta property="og:image:width" content="2048" />
        <meta property="og:image:height" content="2048" />
        <meta name="twitter:card" content="summary" />
        <link
          rel="canonical"
          href={`${import.meta.env.VITE_REPAIR_WEB_URL || 'https://nagaku.com'}/${account.screen_name || `accounts/${account.gid}`}`}
        />
      </Helmet>
      <AccountProfile account={account} />
      {account.business_profile && (
        <div ref={openTicketBarRef}>
          <OpenTicketBar account={account} />
        </div>
      )}
      {account.business_profile && <OpenTicketStickyBar account={account} isHidden={openTicketBarRefInView} />}
      <AccountDebug account={account} />
      {account.business_profile && (
        <AccountNavigation key={location.key} onValueChange={onNavigate} defaultValue={defaultValue} />
      )}
      <AccountContext.Provider value={account}>
        <Outlet />
      </AccountContext.Provider>
    </NavigationLayout>
  );
};

export const accountProfileLoader: PageLoader = async ({ request, params, api }) => {
  const { gid, screen_name } = params;

  if (gid && !isValidULID(gid) && gid !== 'me') {
    throw new NotFoundError('Invalid GID format');
  }

  if (screen_name && !isValidScreenName(screen_name)) {
    throw new NotFoundError('Invalid screen_name format');
  }

  const { account } = await api.fetch<{ account: Account }>(`/v1/accounts/${gid || screen_name}`, {
    include: 'business_profile',
  });
  if (gid == 'me') {
    const trustAccountPath = account.screen_name ? `/${account.screen_name}` : `/accounts/${account.gid}`;
    return redirect(request.url.replace(/\/accounts\/me/, trustAccountPath), { status: 302 });
  }
  if (account.screen_name && !screen_name) {
    return redirect(request.url.replace(`/accounts/${account.gid}`, `/${account.screen_name}`), { status: 301 });
  }
  return { account };
};
