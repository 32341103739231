import { parseRequest } from 'src/lib/server';

export const resolveReturnPath: (searchParams: URLSearchParams) => string = (searchParams) => {
  const returnTo = searchParams.get('return_to');
  if (!returnTo?.startsWith('/')) return '/';
  return returnTo || '/';
};

type OAuthFlowResponse = {
  accessToken?: string;
  refreshToken?: string;
  params: Record<string, string>;
  authenticated: boolean;
};

export const startGoogleOAuthFlow = async (req: Request) => {
  const params = parseRequest(req);
  throw await fetch(`${import.meta.env.VITE_REPAIR_API_URL}${params.url.pathname}${params.url.search}`, {
    method: req.method,
    headers: {
      // Inherit headers from the browser request.
      ...Object.fromEntries(req.headers.entries()),
      // When receive callback from OAuth provider,
      // the origin header is not set.
      // So we need to set it manually for validate redirect URL in development environment.
      origin: req.headers.get('Origin') || `${import.meta.env.VITE_REPAIR_WEB_URL || 'null'}`,
      // The request from browser has no api key.
      // So we need to set it manually to specify the app.
      'x-api-key': import.meta.env.VITE_REPAIR_API_KEY,
    },
    redirect: 'manual',
  });
};

export const callbackGoogleOAuthFlow = async (req: Request) => {
  const params = parseRequest(req);
  const response = await fetch(`${import.meta.env.VITE_REPAIR_API_URL}${params.url.pathname}${params.url.search}`, {
    method: req.method,
    headers: {
      // Inherit headers from the browser request.
      ...Object.fromEntries(req.headers.entries()),
      // When receive callback from OAuth provider,
      // the origin header is not set.
      // So we need to set it manually for validate redirect URL in development environment.
      origin: req.headers.get('Origin') || `${import.meta.env.VITE_REPAIR_WEB_URL || 'null'}`,
      // The request from browser has no api key.
      // So we need to set it manually to specify the app.
      'x-api-key': import.meta.env.VITE_REPAIR_API_KEY,
    },
    redirect: 'manual',
  });

  // When oauth process has error, omniauth responses 302 redirect to the error page. e.g. `/auth/failure`
  if (response.status > 300) {
    throw response;
  }

  const data: OAuthFlowResponse = await response.json();
  const cookie = response.headers.get('Set-Cookie');

  if (data.authenticated) {
    throw new Response(null, {
      status: 302,
      headers: {
        'Set-Cookie': cookie || '',
        location: `${import.meta.env.VITE_REPAIR_WEB_URL}${`/${data.params.return_to || ''}`.replace(/\/+/g, '/')}`,
      },
    });
  }

  throw new Response(null, {
    status: 401,
    headers: {
      'Set-Cookie': cookie || '',
    },
  });
};
